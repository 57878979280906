import { GetAccessToken, SetAccessToken } from "../authentication";
import axios from "axios";

// You can now use the formData object as needed, such as sending it via AJAX or submitting a form

const postData = async (fields, url, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let token = GetAccessToken();
      const formData = new FormData();
      let isUploading = false;

      Object.entries(fields).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          return;
        }

        // If value is array or object
        if (typeof value === "object") {
          // Check if it's a File array
          if (value[0] instanceof File) {
            formData.append(key, value[0]);
            isUploading = true;
          }
          // Check if it's an array
          else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          }

          // If it's any other object
          else {
            // formData.append(key, value);
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              formData.append(`${key}[${nestedKey}]`, nestedValue);
            });
          }
        } else {
          formData.append(key, value);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API}${url}`, formData, {
        headers: {
          "Content-Type": isUploading ? "multipart/form-data" : "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }

      if (response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
        return resolve({ status: response.status, data: [] });
      }

      return resolve({ status: response.status, data: response.data });
    } catch (error) {
      console.log("error", error);
      resolve({
        status: error?.response?.status,
        customMessage: error?.response?.data?.customMessage ?? "Something went wrong!",
        data: error?.response?.data,
      });
    }
  });

  return data;
};
const postDataCustom = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let token = GetAccessToken();
      const formData = new FormData();

      let isUploading = false;
      Object.entries(fields).forEach(([key, value]) => {
        if (typeof value === "object") {
          if (value[0] instanceof File) {
            formData.append(key, value[0]);
            isUploading = true;
          } else {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          }
        } else {
          formData.append(key, value);
        }
      });

      const response = await axios.post(`${ulr}`, formData, {
        headers: {
          "Content-Type": isUploading ? "multipart/form-data" : "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
          return resolve({ status: response.status, data: [] });
        } catch (error) {
          console.log(error);
        }
      }

      resolve({ status: response.status, data: response.data });
    } catch (error) {
      // console.log("error", error);
      resolve({
        status: error?.response?.status,
        customMessage: error?.response?.data?.customMessage ?? "Something went wrong!",
        data: error?.response?.data,
      });
    }
  });

  return data;
};
const putData = async (fields, ulr, dispatch, navigate) => {
  console.log("PutData", fields, { ulr });
  try {
    let token = GetAccessToken();
    let formData = new FormData();
    const apiUrl = process.env.REACT_APP_API;
    let isUploading = false;

    Object.entries(fields).forEach(([key, value]) => {
      if (typeof value === "object") {
        if (value[0] instanceof File) {
          isUploading = true;
          console.log(value[0] instanceof File);
          formData.append(key, value[0]);
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else if ("value" in value) {
          formData.append(key, value._id);
        } else {
          Object.entries(value).forEach(([subKey, subValue]) => {
            formData.append(`${key}[${subKey}]`, subValue);
          });
        }
      } else {
        formData.append(key, value);
      }
    });

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    const response = await axios.put(`${apiUrl}${ulr}`, formData, {
      headers: {
        "Content-Type": isUploading ? "multipart/form-data" : "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const tokenUpdated = response.headers.get("Token-Updated");
    if (tokenUpdated === "true") {
      const newToken = response.headers.get("Authorization").split(" ")[1];
      SetAccessToken(newToken);
    }

    if (response.status === 401) {
      try {
        localStorage.clear();
        window.location.href = "/";
        return { status: response.status, data: [] };
      } catch (error) {
        console.log(error);
      }
    }

    return { status: response.status, data: response.data };
  } catch (error) {
    console.log(error);
    return {
      status: error.response?.status,
      customMessage: error.response?.data?.customMessage ?? "Something went wrong!",
      data: error?.response?.data,
    };
  }
};
const bulkUploadData = async (formData, url, signal, dispatch, navigate) => {
  try {
    let token = GetAccessToken();

    const response = await axios.post(`${process.env.REACT_APP_API}${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
      signal: signal,
    });
    const tokenUpdated = response.headers.get("Token-Updated");
    if (tokenUpdated === "true") {
      const newToken = response.headers.get("Authorization").split(" ")[1];
      SetAccessToken(newToken);
    }
    // Handle unauthorized access
    if (response.status === 401) {
      // dispatch(clearLogin()); // Uncomment if you have a clearLogin action
      navigate("/");
      navigate(0);
    }

    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("error", error);
    return {
      status: error.response?.status ?? error,
      data: error.response?.data?.message || "An error occurred while uploading.", // Provide a default message
    };
  }
};

const getData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let params = new URLSearchParams();

      // Function to recursively add parameters
      const addParams = (obj, prefix = "") => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let paramKey = prefix ? `${prefix}[${key}]` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
              addParams(obj[key], paramKey);
            } else {
              params.append(paramKey, obj[key]);
            }
          }
        }
      };

      // Add all fields to params
      addParams(fields);

      let token = GetAccessToken();
      const hasQueryString = ulr.includes("?");
      const separator = hasQueryString ? "&" : "?";
      const fullUrl = `${process.env.REACT_APP_API}${ulr}${separator}${params.toString()}`;

      const response = await axios.get(fullUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (error) {
          console.log(error);
        }
      }
      resolve({ status: response.status, data: response.data });
    } catch (error) {
      if (error.response?.status) {
        if (error.response?.status === 401) {
          try {
            localStorage.clear();
            window.location.href = "/";
          } catch (error) {
            console.log(error);
          }
        }
      }
      resolve({
        status: error.response?.status,
        data: error.response?.data?.message,
      });
    }
  });
  return data;
};
const getCustomData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let params = new URLSearchParams();

      // Function to recursively add parameters
      const addParams = (obj, prefix = "") => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let paramKey = prefix ? `${prefix}[${key}]` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
              addParams(obj[key], paramKey);
            } else {
              params.append(paramKey, obj[key]);
            }
          }
        }
      };

      // Add all fields to params
      addParams(fields);

      let token = GetAccessToken();
      const hasQueryString = ulr.includes("?");
      const separator = hasQueryString ? "&" : "?";
      const fullUrl = `${ulr}${separator}${params.toString()}`;

      const response = await axios.get(fullUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (error) {
          console.log(error);
        }
      }
      resolve({ status: response.status, data: response.data });
    } catch (error) {
      if (error.response?.status) {
        if (error.response?.status === 401) {
          try {
            localStorage.clear();
            window.location.href = "/";
          } catch (error) {
            console.log(error);
          }
        }
      }
      resolve({
        status: error.response?.status,
        data: error.response?.data?.message,
      });
    }
  });
  return data;
};
const deleteData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let token = GetAccessToken();
      let queryString = Object.keys(fields)
        .map((key) => key + "=" + fields[key])
        .join("&");
      const response = await axios.delete(`${process.env.REACT_APP_API}${ulr}?${queryString}`, {
        headers: { Authorization: "Bearer " + token },
      });
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (error) {
          console.log(error);
        }
      }
      resolve({ status: response.status, data: response.data });
    } catch (error) {
      console.log("API Eror", error.message);
      resolve({
        status: error.response.status,
        customMessage: error.response?.data?.customMessage ?? "Something went wrong!",
      });
    }
  });

  return data;
};
const getBlobData = async (fields, ulr, dispatch, navigate) => {
  console.log("getBlobData called with URL:", ulr);
  const data = new Promise(async (resolve, reject) => {
    try {
      let params = new URLSearchParams();

      // Function to recursively add parameters
      const addParams = (obj, prefix = "") => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let paramKey = prefix ? `${prefix}[${key}]` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
              addParams(obj[key], paramKey);
            } else {
              params.append(paramKey, obj[key]);
            }
          }
        }
      };

      // Add all fields to params
      addParams(fields);

      let token = GetAccessToken();
      console.log("Token retrieved:", token ? "Yes" : "No");

      const hasQueryString = ulr.includes("?");
      const separator = hasQueryString ? "&" : "?";
      const fullUrl = `${process.env.REACT_APP_API}${ulr}${separator}${params.toString()}`;
      console.log("Making request to:", fullUrl);

      const response = await axios.get(fullUrl, {
        headers: {
          Accept: "application/pdf",
          Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
      });
      console.log("Response received:", {
        status: response.status,
        hasData: !!response.data,
        headers: response.headers,
      });

      // Check token update in headers (fixed from headers.get to direct access)
      const tokenUpdated = response.headers["token-updated"];
      if (tokenUpdated === "true") {
        console.log("Updating token");
        const newToken = response.headers["authorization"]?.split(" ")[1];
        if (newToken) {
          SetAccessToken(newToken);
        }
      }

      if (response.status === 401) {
        console.error("Unauthorized access");
        try {
          localStorage.clear();
          window.location.href = "/";
          return resolve({ status: response.status, data: null });
        } catch (error) {
          console.error("Error during unauthorized handling:", error);
        }
      }

      // Convert array buffer to blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      console.log("Blob created:", {
        size: blob.size,
        type: blob.type,
      });

      resolve({
        status: response.status,
        data: blob,
        headers: response.headers,
      });
    } catch (error) {
      console.error("getBlobData error:", error);
      if (error.response?.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (cleanupError) {
          console.error("Error during error handling:", cleanupError);
        }
      }
      resolve({
        status: error.response?.status,
        data: null,
        error: error.message || "Failed to fetch PDF",
      });
    }
  });
  return data;
};

export { getData, postData, putData, deleteData, bulkUploadData, getCustomData, postDataCustom, getBlobData };
